import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ResultCard from './ResultCard';
import ResultHeader from './ResultHeader';
import ResultTable from './ResultTable';
import Text from '../../common/Text';
import Locale from '../../Translate/Locale';
import PlainButton from '../../common/PlainButton/PlainButton';
import { formatDate } from './dateHelper';
import MenuItem from '../../common2/DropdownButton/MenuItem';
import ButtonDropdown from '../../common2/ButtonDropdown/ButtonDropdown';
import container from '../../../container';
import useNotifications from '../../../hooks/useNotifications';
import ClaimsIcon from '../../../icons/claim.svg';
import DownloadIcon from '../../../icons/download-csv.svg';

import './ShipmentCard.scss';

const languageToLabel = {
  en: 'English',
  de: 'German',
  fr: 'French',
  es: 'Spanish',
  nl: 'Dutch',
};

function ShipmentCard(props) {
  const {
    id,
    creationDate,
    trackingCode,
    customer = {},
    carrier = {},
    lastEvent,
    onClaim,
    claimable,
    claimId,
    claimStatus,
    claimType,
    hasShipmentDetailsAccess,
    availableDORLanguages = [],
    deliveryDate,
  } = props;

  const orderUrl = `/parcel-finder/shipments/${id}`;
  const showClaimButton = claimable && !claimId;
  const showClaimedTag = !!claimId;
  const showDownloadDORButton = availableDORLanguages.length > 0; // && !!deliveryDate;
  const mappedAvailableLanguages = availableDORLanguages
    .map((language) => ({ key: language, value: languageToLabel[language] }))
    .sort((a, b) => a.value.localeCompare(b.value));

  const { showError } = useNotifications();

  const downloadDOR = useCallback(
    async (language) => {
      try {
        const response = await container.touchpointsApi.downloadDor(id, language);
        const blob = await response.blob();
        const filename = `${trackingCode}_${language}_dor.pdf`;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');

        link.href = url;
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } catch (error) {
        showError('There was an error downloading the file');
      }
    },
    [id, trackingCode, showError]
  );

  return (
    <ResultCard>
      <ResultHeader
        type="shipment"
        name="Shipment"
        labelForId="Shipment tracking code"
        id={trackingCode}
        lastEvent={lastEvent}
        link={orderUrl}
        claimStatus={claimStatus}
        claimType={claimType}
        showClaimedTag={showClaimedTag}
        showDetailsButton={hasShipmentDetailsAccess}
      >
        {showDownloadDORButton && (
          <ButtonDropdown icon={DownloadIcon} text={'Download DOR'}>
            {mappedAvailableLanguages.map(({ key: language, value: label }) => (
              <MenuItem key={language} onClick={() => downloadDOR(language)}>
                <Locale>{label}</Locale>
              </MenuItem>
            ))}
          </ButtonDropdown>
        )}
        {showClaimButton && (
          <PlainButton icon={ClaimsIcon} onClick={onClaim}>
            Submit a claim
          </PlainButton>
        )}
      </ResultHeader>
      <ResultTable>
        <thead styleName="table-header">
          <tr>
            <th>
              <Text>
                <Locale>Creation date</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Name</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Address</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Email</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Carrier</Locale>
              </Text>
            </th>
            <th>
              <Text>
                <Locale>Carrier country</Locale>
              </Text>
            </th>
          </tr>
        </thead>
        <tbody styleName="table-body">
          <tr>
            <td>
              <Text ink>{formatDate(creationDate)}</Text>
            </td>
            <td>
              <Text ink>{customer.name}</Text>
            </td>
            <td>
              <Text ink>{customer.address}</Text>
            </td>
            <td>
              <Text ink>{customer.email}</Text>
            </td>
            <td>
              <Text ink>{carrier.name}</Text>
            </td>
            <td>
              <Text ink>{carrier.countryName}</Text>
            </td>
          </tr>
        </tbody>
      </ResultTable>
    </ResultCard>
  );
}

ShipmentCard.propTypes = {
  id: PropTypes.number,
  creationDate: PropTypes.string,
  trackingCode: PropTypes.string,
  customer: PropTypes.shape({
    name: PropTypes.string,
    address: PropTypes.string,
    email: PropTypes.string,
  }),
  lastEvent: PropTypes.shape({
    type: PropTypes.string,
    meaning: PropTypes.oneOf(['really_bad', 'bad', 'good', 'neutral']),
  }),
  carrier: PropTypes.shape({
    name: PropTypes.string,
    countryName: PropTypes.string,
  }),
  onClaim: PropTypes.func,
  claimable: PropTypes.bool,
};

export default React.memo(ShipmentCard);

import React, { useEffect } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CarriersPage, DashboardPage, ExplorePage } from '../analytics';
import withPermissionRedirect from '../../wrappers/withPermissionRedirect';
import * as permissions from '../../firewall/permissions';
import Error404 from '../error-pages/Error404';
import AnalyticsStartGuidePage from '../start-guides/AnalyticsStartGuidePage';

import { useDispatch, useSelector } from 'react-redux';
import { selectAnalyticsDashboards, getAnalyticsDashboards } from '../../reducers/analyticsDashboardsReducer';
import PageLoader from '../PageLoader';
import { useSelectedShopId } from '../../hooks/useSelectedShop';
import { useAnalyticsMenuItems } from '../analytics/useAnalyticsMenuItems';

const withAnalyticsAccess = withPermissionRedirect(permissions.ANALYTICS_PERMISSION, '/analytics/start-guide');
const withAnalyticsExploreAccess = withPermissionRedirect(permissions.ANALYTICS_EXPLORE, '/analytics/explore');

const withDashboardIdAndType = (dashboardId, type) => (props) => (
  <DashboardPage key={dashboardId} dashboardId={dashboardId} type={type} {...props} />
);

const OrderStatusOverview = withDashboardIdAndType('order_status_overview');
const OrderFulfillmentPerformance = withDashboardIdAndType('order_fulfillment_performance');
const OrderList = withDashboardIdAndType('order_list');
const OrderStatusChangeOutliers = withDashboardIdAndType('days_from_order_status_changed_outliers', 'look');

const OrderRoutes = () => (
  <Switch>
    <Route exact path="/analytics/orders/status-overview" component={OrderStatusOverview} />
    <Route exact path="/analytics/orders/fulfillment-performance" component={OrderFulfillmentPerformance} />
    <Route exact path="/analytics/orders/list" component={OrderList} />
    <Route exact path="/analytics/orders/days-from-status-changed-outliers" component={OrderStatusChangeOutliers} />
    <Redirect from="/analytics/orders/:id(\d+)" to="/parcel-finder/orders/:id" />
    <Redirect from="/analytics/orders" to="/analytics/orders/status-overview" />
  </Switch>
);

const ShipmentStatusOverview = withDashboardIdAndType('shipment_status_overview');
const ShipmentCarrierPerformance = withDashboardIdAndType('shipment_carrier_performance');
const ShipmentList = withDashboardIdAndType('shipment_list');
const ShipmentCarrierComparison = withDashboardIdAndType('shipment_carrier_comparison');
const ShipmentStatusChangeOutliers = withDashboardIdAndType('days_from_shipment_status_changed_outliers', 'look');
const ReturnOverview = withDashboardIdAndType('returns_overview');
const ClaimsOverview = withDashboardIdAndType('claims_overview');
const ClaimsOverviewWithAccess = withAnalyticsAccess(ClaimsOverview);

const ShipmentRoutes = () => (
  <Switch>
    <Route exact path="/analytics/shipments/carrier-comparison" component={ShipmentCarrierComparison} />
    <Route exact path="/analytics/shipments/carrier-performance" component={ShipmentCarrierPerformance} />
    <Route exact path="/analytics/shipments/list" component={ShipmentList} />
    <Route exact path="/analytics/shipments/status-overview" component={ShipmentStatusOverview} />
    <Route exact path="/analytics/shipments/returns-overview" component={ReturnOverview} />
    <Route
      exact
      path="/analytics/shipments/days-from-status-changed-outliers"
      component={ShipmentStatusChangeOutliers}
    />
    <Redirect from="/analytics/shipments/:id(\d+)" to="/parcel-finder/shipments/:id" />
    <Route component={Error404} />
  </Switch>
);

const OrderRoutesWithAccess = withAnalyticsAccess(OrderRoutes);
const ShipmentRoutesWithAccess = withAnalyticsAccess(ShipmentRoutes);
const ExplorePageWithAccess = withAnalyticsExploreAccess(ExplorePage);
const CarriersPageWithAccess = withAnalyticsAccess(CarriersPage);
const CountryViewWithAccess = withAnalyticsAccess((props) => (
  <DashboardPage key={props.match.params.id} dashboardId={props.match.params.id} type="dashboard" {...props} />
));
const LooksViewWithAccess = withAnalyticsAccess((props) => (
  <DashboardPage key={props.match.params.id} dashboardId={props.match.params.id} type="look" {...props} />
));

const AnalyticsRoutes = () => {
  const dispatch = useDispatch();
  const shopId = useSelectedShopId();
  const { isLoaded } = useSelector(selectAnalyticsDashboards);
  const { fixedDashboards, customDashboards, countryViews } = useAnalyticsMenuItems();

  useEffect(() => {
    dispatch(getAnalyticsDashboards(shopId));
  }, [dispatch, shopId]);

  // we display loader only on initial load
  // subsequent navigations to /analytics display cached data and load in background without loader for better UX
  if (!isLoaded) {
    return <PageLoader />;
  }

  const defaultDashboardRoute =
    fixedDashboards[0]?.link || customDashboards[0]?.link || countryViews[0]?.link || '/analytics/start-guide';

  return (
    <Switch>
      <Route path="/analytics/orders" component={OrderRoutesWithAccess} />
      <Route path="/analytics/shipments" component={ShipmentRoutesWithAccess} />
      <Route path="/analytics/explore" component={ExplorePageWithAccess} />
      <Route path="/analytics/start-guide" component={AnalyticsStartGuidePage} />
      <Route exact path="/analytics/looks/:id" component={LooksViewWithAccess} />
      <Route exact path="/analytics/dashboards/:id" render={CountryViewWithAccess} />
      <Route exact path="/analytics/carriers" component={CarriersPageWithAccess} />
      <Route exact path="/analytics/claims/claims-overview" component={ClaimsOverviewWithAccess} />
      <Redirect exact path="/analytics" to={defaultDashboardRoute} />
    </Switch>
  );
};

export default AnalyticsRoutes;
